<template>
    <v-container class="ma-0 pa-0">
        <v-row class="py-10 px-8">
            <strong>Filtros</strong>

            <v-select @keydown.enter="filter()" v-model="company.semaforo" :items="semaforos" item-text="name" item-value="id" attach chips label="Semaforo" multiple>
            </v-select>

            <div class="mt-4">
                Fecha de creación 
                <v-menu v-model="menuFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="company.dateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="company.dateFrom" @input="menuFrom = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="menuTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="company.dateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="company.dateTo" @input="menuTo = false"></v-date-picker>
                </v-menu>
            </div>

            <v-text-field @keydown.enter="filter()" v-model="company.name" label="Nombre"></v-text-field>
            <v-text-field @keydown.enter="filter()" v-model="company.address" label="Dirección"></v-text-field>
            <v-text-field @keydown.enter="filter()" v-model="company.phone" label="Teléfono"></v-text-field>
            <v-text-field @keydown.enter="filter()" v-model="company.email" label="Correo Electrónico"></v-text-field>
            <v-text-field @keydown.enter="filter()" v-model="company.rfc" label="RFC"></v-text-field>
            <v-text-field @keydown.enter="filter()" v-model="company.razon_social" label="Razón Social"></v-text-field>

            <v-select @keydown.enter="filter()" v-model="company.origins" :items="originLists" item-text="name" item-value="id" attach chips label="Procedencia" multiple>
            </v-select>
            
            <v-select @keydown.enter="filter()" v-model="company.phases" :items="phaseLists" item-text="name" item-value="id" attach chips label="Etapa" multiple>
            </v-select>

            <v-select @keydown.enter="filter()" v-model="company.statuses" :items="statusLists" item-text="name" item-value="id" attach chips label="Estatus" multiple>
            </v-select>

            <v-select @keydown.enter="filter()" v-model="company.users" :items="usersLists" item-text="name" item-value="id" attach chips label="Responsable" multiple>
            </v-select>

        </v-row>

        <v-card style="position:absolute; bottom:0; width:100%;" tile color="primary"><!-- # d71182 -->
                    <v-list-item link @click="filter()" dark>
                        <v-list-item-content>
                            <strong>Filtrar (0)</strong>
                        </v-list-item-content>
                    </v-list-item>    
                </v-card>
    </v-container>
</template>
<script>
  export default {
    data: () => ({
        menuTo:false,
        menuFrom:false,
        semaforos:[
            {name: 'verde', id: '#4CAF50',},
            {name: 'amarillo', id: '#FFC107',},
            {name: 'rojo', id: '#FF5252',},
        ],
        lead:{
            users:'',
            origins:'',
            name:'',
            address:'',
            phone:'',
            email:'',
            dateFrom:'',
            dateTo:'',
            semaforo:'',
            company:'',
            subject:''
        },
    }),
    computed: {
        usersLists(){
            return this.$store.state.user.users;
        },
        originLists(){
            return this.$store.state.origin.origins;
        },
    },
    created () {
        
    },
    methods: {
        filter(){
            this.$emit("filtersLead", this.lead);
        }
    }
  }
</script>